<template>
  <a-button class="editable-add-btn" style="margin-bottom: 8px" @click="handleAdd">Add</a-button>
  <a-table bordered :data-source="dataSource" :columns="columns">
    <template #bodyCell="{ column, text, record }">

      <template v-if="column.dataIndex === 'location'">
        <div class="editable-cell">
          <div v-if="editableData[record.key]" class="editable-cell-input-wrapper">
            <a-input v-model:value="editableData[record.key].location" @pressEnter="save(record.key)" />
          </div>
          <div v-else class="editable-cell-text-wrapper">
            {{ text || ' ' }}
          </div>
        </div>
      </template>
      <template v-if="column.dataIndex === 'riskLevel'">
        <div class="editable-cell">
          <div v-if="editableData[record.key]" class="editable-cell-input-wrapper">
            <a-input v-model:value="editableData[record.key].riskLevel" @pressEnter="save(record.key)" />
          </div>
          <div v-else class="editable-cell-text-wrapper">
                        <span>
              <a-tag
                  :color="getRiskColor(text)"
              >
            {{ text || ' ' }}
          </a-tag>
        </span>
          </div>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div>
          <div v-if="editableData[record.key]" >
            <check-outlined class="editable-cell-icon-check" v-if="!loadingSource" @click="save(record.key)" />
            <a-spin v-if="loadingSource" />
          </div>
          <div v-else >
<!--            <edit-outlined class="editable-cell-icon" @click="edit(record.key)" />-->
            <a-button @click="edit(record.key)" style="margin-left: 10px" >Edit</a-button>
            <a-popconfirm
                v-if="dataSource.length"
                title="Sure to delete?"
                @confirm="onDelete(record.key)"
            >
              <a-button danger style="margin-left: 10px"  >Delete</a-button>
            </a-popconfirm>

            <a-button type="primary" @click="showModal(record.key)" style="margin-left: 10px" >QR Code</a-button>
          </div>

        </div>

      </template>
    </template>
  </a-table>

  <a-modal v-model:visible="visible" title="QR Code" @ok="handleOk">
    <img :src="qrCode" alt="" />
  </a-modal>
</template>

<script>
import { computed, defineComponent, reactive, ref } from 'vue';
import { CheckOutlined, EditOutlined } from '@ant-design/icons-vue';
import { cloneDeep } from 'lodash-es';
import { collection, getDocs, getFirestore, doc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import QRCode from "qrcode";
import aes256 from "aes256";


const data = []
const isLoad = false
export default {
  name: "CheckInCode",
  components: {
    CheckOutlined,
    EditOutlined,
  },
  setup() {
    var aes256 = require('aes256');
    var QRCode = require('qrcode')
    const visible = ref(false);
    const qrCode = ref("");

    const showModal = async key => {
      let data = dataSource.value.filter(item => key === item.key)[0]

      const dataToQR = {
        location: data.location,
        checkInCode: data.checkInCode
      }
      await QRCode.toDataURL(aes256.encrypt("key", JSON.stringify(dataToQR)))
            .then(res => {
              qrCode.value = res
              console.log(res)
            })
      visible.value = true;
    };
    const handleOk = e => {
      console.log(e);
      visible.value = false;
    };
    const columns = [{
      title: 'Check In Code',
      dataIndex: 'checkInCode',
      width: '10%',
    }, {
      title: 'Location',
      dataIndex: 'location',
      width: "40%"
    }, {
      title: 'Risk Level',
      dataIndex: 'riskLevel',
      width: '10%'
    }, {
      title: 'operation',
      dataIndex: 'operation',
    }];
    const dataSource = ref(data);
    const count = computed(() => dataSource.value.length + 1);
    const editableData = reactive({});
    const db = getFirestore();
    const loadingSource = ref(isLoad);

    const edit = key => {
      editableData[key] = cloneDeep(dataSource.value.filter(item => key === item.key)[0]);
    };

    const save = async key => {
      loadingSource.value = true
      Object.assign(dataSource.value.filter(item => key === item.key)[0], editableData[key]);
      console.log(editableData[key])
      await updateDoc(doc(db, "CheckInCode", editableData[key].checkInCode), {
        location: editableData[key].location,
        riskLevel: editableData[key].riskLevel
      });
      loadingSource.value = false
      delete editableData[key];
    };

    const onDelete = async key => {
      await deleteDoc(doc(db, "CheckInCode", dataSource.value.filter(item => key === item.key)[0].checkInCode));
      dataSource.value = dataSource.value.filter(item => item.key !== key);
    };

    const handleAdd = async () => {
      const newData = {
        key: `${count.value}`,
        checkInCode: uuidv4().substr(0,6),
        location: uuidv4().substr(0,6) + " Location",
        riskLevel: `Low Level`,
      };
      const needToInsert = {
        location: newData.location,
        riskLevel: newData.riskLevel
      }

      await setDoc(doc(db, "CheckInCode", newData.checkInCode), needToInsert);
      dataSource.value.push(newData);
    };

    return {
      columns,
      onDelete,
      handleAdd,
      dataSource,
      editableData,
      count,
      edit,
      save,
      loadingSource,
      visible,
      showModal,
      handleOk,
      qrCode
    };
  },
  async created() {
    const dataSource = ref(data)
    const db = getFirestore();
    const querySnapshot = await getDocs(collection(db, "CheckInCode"));
    let index = 0;
    while (dataSource.value.length !== 0) {
      dataSource.value.pop()
    }
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots

      console.log(index)
      let singleOne = doc.data();
      singleOne['key'] = index + "";
      singleOne['checkInCode'] = doc.id
      dataSource.value.push(singleOne)
      console.log(doc.id, " => ", doc.data());
      index++;
    });

  },
  methods:{
    getRiskColor(riskLevel){
      if (riskLevel.toLowerCase().includes("low")) {
        return "green"
      }
      if (riskLevel.toLowerCase().includes("mid")) {
        return 'yellow'
      }
      return 'red'
    }
  }

}
</script>

<style lang="less">
.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>